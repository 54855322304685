/* These are global styles for the whole app */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root,
html,
body {
  --navbar-height: 70px;
  padding: 0;
  margin: 0;
  font-family: 'Sora', sans-serif !important;
  background: white;
  position: relative;
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip,
.has-tooltip:focus .tooltip,
.has-tooltip:active .tooltip {
  @apply visible z-50;
}

a {
  color: inherit;
  text-decoration: none;
}

.page-w-navbar {
  height: 100vh;
  width: 100%;
}

.page-w-navbar-content {
  margin-top: 87px;
  height: calc(100vh - 87px);
}

.nav-item {
  display: flex;
}

.reset-password {
  width: 450px;
}

.agreements__clear {
  color: #FAD446 !important;
}

.agreements > .agreements__description::-webkit-scrollbar-thumb {
  background-color: #FAD446 !important;
}

.agreements__divider {
  padding: 0 0 1.5rem 0;
}

.menu-dropdown-container {
  background-color: white;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

.crosswind {
  background-size: 400%;
  -webkit-animation: crosswind-animation 3s ease infinite;
  -moz-animation: crosswind-animation 3s ease infinite;
  animation: crosswind-animation 3s ease infinite;
}

.throb {
  animation: throb-animation 1.5s ease infinite;
}

[type='reset'], [type='submit'] {
  background-color: #FAD446; /* #d51e3e */
}

button.log-in-form__create-button {
  margin-top: 10px;
}
p.log-in-form__create-button {
  font-size: 14px !important;
}

.checkbox-wrapper:has(#rememberMe) {
  display: none;
}

.checkbox-wrapper > input[type='checkbox'], input[type='checkbox']:focus {
  appearance: auto;
  color: #FAD446; /* #d51e3e */
  outline-color: #FAD446; /* #d51e3d85 */
  --tw-ring-color: #FAD446; /* #d51e3d85 */
}

.modal {
  background-color: white;
}

.initials-agreement {
  padding: 0 5px;
}

.sigCanvas {
  height: 100px !important;
  width: 600px !important;
}

button.download-software-wrapper__finish-button {
  display: none !important;
}
button.send-tab-continue-form__button {
  background-color: #d41e3e;
}
input#isAdult {
  appearance: checkbox;
  border-radius: 2px;
  margin-bottom: 5px;
}


@keyframes throb-animation {
  0%, 100% {
    opacity: 65%;
  }
  50% {
    opacity: 25%;
  }
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

@keyframes crosswind-animation {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.createPasscode .inputs .input {
  width: auto;
  max-width: 400px;
}

.markdown h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.6em;
}
.markdown h3 {
  font-size: 1.15em;
  font-weight: 500;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}
.markdown h4 {
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 0.5em;
}
.markdown p {
  margin-bottom: 0.4em;
  margin-top: 0.4em;
}
.markdown strong {
  font-weight: 500;
}
.markdown ol, .markdown ul {
  padding-left: 1.5em;
}
.markdown ol {
  list-style: decimal;
}
.markdown ol > li > ol {
  list-style: lower-alpha;
}
.markdown ul {
  list-style: disc;
}

.markdown table {
  width: 100%;
  max-width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: transparent;
  border-collapse: collapse;
}

.markdown th {
  vertical-align: bottom;
  border: 1px solid #dee2e6;
  border-bottom: 2px solid #999;
  background-color: #EEE;
  text-align: left;
  font-weight: bold;
  padding: 0.25em 0.5em;
}

.markdown td {
  padding: 0.25em 0.5em;
  border: 1px solid #dee2e6;
}

@media (max-width: 1024px) {
  .createPasscode .inputs .input {
    width: auto;
    max-width: 300px;
  }
}

@media (max-width: 768px) {
  .createPasscode .inputs .input {
    width: 100%;
    max-width: 100%;
  }
}


