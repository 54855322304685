.app-container {
  height: 100vh;
}

.sessionExpiredModal {
  width: 417px;
  display: flex;
  width: 100%;
  padding: 0;
  justify-content: center;
  height: 100vh;
}

.sessionModalImgDiv {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
}
.sessionModalImg {
  width: 48px;
}

@media (max-width: 767px) {
  .appContainer {
    isolation: isolate;
    --navBar-height: 80px;
    --horizontal-padding: 32px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: var(--navBar-height) 1fr;
    grid-template-areas:
      'navbar'
      'main';
    grid-column: 0px;
    grid-row-gap: 0px;
    height: 100vh;
    transition: width 200ms cubic-bezier(0.77, 0, 0.175, 1);
  }

  .app-body {
    padding: 0;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  }

  .container > * {
    box-shadow: none !important;
    border: none !important;
  }
  .sessionExpiredModal {
    width: 336px;
  }
}
